@import "./media.scss";

.header-wrapper {

    margin-top: 20vh;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    h2 {
      font-size: 3rem;
      text-align: center;
      @include media(">=phone", "<lgphone") {
        font-size: 14px;
      }
      @include media(">=lgphone", "<tablet") {
        font-size: 16px;
      }
      @include media(">=tablet", "<desktop") {
        font-size: 16px;
      }
    }
    .heading-wrapper {
      h1 {
        font-size: 3rem;
        text-align: center;
        line-height: 40px;
        @include media(">=phone", "<lgphone") {
          font-size: 1.5rem;
          line-height: 10px;
        }
        @include media(">=lgphone", "<tablet") {
          font-size: 1.5rem;
          line-height: 10px;
        }
        @include media(">=tablet", "<desktop") {
          font-size: 2.5rem;
        }
      }
    }
    p {
      width: 50%;
      text-align: center;
      font-size: 1rem;
      overflow: hidden;
      @include media(">=phone", "<lgphone") {
        font-size: 12px;
        width: 100%;
      }
      @include media(">=lgphone", "<tablet") {
        font-size: 12px;
        width: 100%;
      }
      @include media(">=tablet", "<desktop") {
        font-size: 14px;
        width: 100%;
      }
    }
    .nameplate{
        font-size: 1.8rem;
    }
  }