.card {
    width: 100%;
    height: 250px;
    overflow: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    
  
    .content {
      font-family: "Open Sans";
      text-align: center;
      padding: 10px;
      opacity: 0;
      transition: all 500ms ease-in-out;
      transform: translate(0, 20px);
      background-image: linear-gradient(217deg,rgba(130, 186, 190, .9) , rgba(255,0,0,0)),
        linear-gradient(127deg, rgba(164,130,190,.9) , rgba(0,255,0,0)),
        linear-gradient(336deg, rgba(190, 134, 130,.9), rgba(0,0,255,0));
      cursor: pointer;
      h1 {
        font-size: 14px;
        color: rgb(0, 0, 0);
        font-weight: bolder;
        background-image: linear-gradient(217deg,rgba(130, 186, 190, .9) , rgba(255,0,0,0)),
        linear-gradient(127deg, rgba(164,130,190,.9) , rgba(0,255,0,0)),
        linear-gradient(336deg, rgba(190, 134, 130,.9), rgba(0,0,255,0)); ;
      }
      p {
        font-size: 12px;
        color: rgb(0, 0, 0);
        margin-bottom: 20px;
        font-weight: bold;
        background-image: linear-gradient(217deg,rgba(130, 186, 190, .9) , rgba(255,0,0,0)),
        linear-gradient(127deg, rgba(164,130,190,.9) , rgba(0,255,0,0)),
        linear-gradient(336deg, rgba(190, 134, 130,.9), rgba(0,0,255,0)); ; ;
      }
  
      .btn {
        background-color: black;
        color: white;
        padding: 10px;
        text-decoration: none;
      }
      &:hover {
        opacity: 1;
        transform: translate(0, 0px);
        width: 100%;
        height: max-content;
        
      }
    }
  }