$primary-color: #49423f;

@import 'animate.css';
@import 'loaders.css/src/animations/line-scale-random.scss';
@import 'loaders.css/src/animations/triangle-skew-spin.scss';
@import 'loaders.css/src/animations/ball-scale-ripple-multiple.scss';
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}
@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}
@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,
textarea {
  font-family: 'Helvetica Neue';
}

* {
  font-family: "Montserrat";
}

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: url("./assets/images/bubbles.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;


}

.section {
  width: 100vw;
  height: auto;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
@media screen and (max-width: 769px) {
    .container {
      flex-direction: column;

    }
  }

.primary-btn {
  margin: 10px;
  padding: 10px;
  background-color: black;
  color: white;
  border: 0px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    background-image:linear-gradient(217deg, rgba(130, 186, 190,1), rgba(255,0,0,0) 70.71%),
    linear-gradient(127deg, rgba(164,130,190, 1), rgba(0,255,0,0) 70.71%),
    linear-gradient(336deg, rgba(190, 134, 130, 1), rgba(0,0,255,0) 70.71%);  
    color: white;
  }
}

a.react-reveal.primary-btn.resume{
  width: 127.656px;
  text-align: center;
}