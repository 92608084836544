.work-wrapper {
    width: 80vw;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 3rem;
      line-height: 20px;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .grid {
      display: grid;
      margin-top: 20px;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
    }
}