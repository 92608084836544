.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
  
    div {
      position: absolute;
      padding-bottom: 25px;
      width: 200px;
      height: 200px;
      border: 1px solid #C0C0C0;
      background-image: linear-gradient(217deg, rgba(130, 186, 190,1), rgba(255,0,0,0) 70.71%),
      linear-gradient(127deg, rgba(164,130,190, 1), rgba(0,255,0,0) 70.71%),
      linear-gradient(336deg, rgba(190, 134, 130, 1), rgba(0,0,255,0) 70.71%);
      text-align: center;
      font-size: 100px;
      display: grid;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px #82828292;
    }
  
    .face1 {
      transform: translateZ(100px);
      color: #f06529;
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #28a4d9;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #5ed4f4;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #2c57a6;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }
  
  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }

.stage-cube-cont {
    margin-top: 130px;
    margin-bottom: 420px;
    
}
