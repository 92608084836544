@import "./media.scss";

.about-section {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  justify-content: space-between;
  
  @include media(">=phone", "<lgphone") {
    flex-direction: column-reverse;
  }
  @include media(">=lgphone", "<tablet") {
    flex-direction: column-reverse;
  }
  @include media(">=tablet", "<desktop") {
    flex-direction: column-reverse;
    align-items: center;
  }
  .content {
    width: 50%;
    @include media(">=phone", "<lgphone") {
      width: 100%;
    }
    @include media(">=lgphone", "<tablet") {
      width: 100%;
    }
    @include media(">=tablet", "<desktop") {
      width: 100%;
    }

    h1 {
      font-size: 3rem;
      line-height: 20px;
      padding-top: 20px;
      padding-bottom: 10px;
      text-align: center;
      color: black;
      @include media(">=tablet", "<desktop") {
        text-align: center;
      }
    }
    p {
      color: black;
      @include media(">=tablet", "<desktop") {
        text-align: center;
      }
    }
  }
  .image-wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @include media(">=phone", "<lgphone") {
      width: 100%;
    }
    @include media(">=lgphone", "<tablet") {
      width: 100%;
    }
    @include media(">=tablet", "<desktop") {
      width: 100%;
    }
    
  }
  
}
img.rounded.float-end{
  width: 500px;
  margin: 10px;
}
